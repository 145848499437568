@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button,
  input,
  optgroup,
  select,
  textarea {
    @apply font-milliard-book;
  }

  button,
  input,
  select:focus {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    outline: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  .md-stripes-bg {
    background: repeating-linear-gradient(
      -45deg,
      #dad6d6,
      #d6dad6 10px,
      #bbc4ba 10px,
      #bbc4ba 20px
    );
  }
  .md-header-filter-today {
    display: none;
  }
}

html {
  background-color: #f7f7f7;
}

#root {
  background-color: #f7f7f7;
  height: 100%;
}

/* .react-datepicker__header {
  background-color: white;

  text-align: 'center';
}
.react-datepicker__day .react-datepicker__day-name {
  width: 40;
  height: 40;
  line-height: 40px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.13;
  text-align: 'center';
  cursor: 'default';
}
.react-datepicker__day {
  color: gray;
  font-size: '13px';
  cursor: 'pointer';
  position: 'relative';
}
.react-datepicker__day--outside-month {
  visibility: 'hidden';
}
.react-datepicker__day--selected {
  background-color: turquoise;
  color: white;
  border-radius: '50%';
}
.react-datepicker__day--disabled {
  color: grey;
}
.react-datepicker__day--today::before {
  content: "''";
  border: solid teal;
  border-radius: 50%;
  border-width: 3px;
  display: inline-block;
  /* position: absolute; */
/* bottom: 6px;
  left: 42.5%;
} */
/* .react-datepicker__day--selected.react-datepicker__day--today::before {
  border: solid white;
} */

.grid-scroll {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem;
  overflow-x: auto;
  width: 100%;
  padding: 1rem;
}

.ReactModalPortal {
  z-index: 1;
}

.slick-slider {
  height: 100%;
}

.slick-slider > .slider-prev {
  z-index: 1;
}

.slick-list {
  height: 100%;
}

.slick-slide {
  height: auto !important;
}

.slick-slide > div {
  height: 100% !important;
}

.slick-track {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100%;
}

.listing-popup > .mapboxgl-popup-content {
  padding: 5px;
  @apply rounded-md;
}

.Modal {
  position: absolute;
  top: 40px;
  right: 50%;
  bottom: 40px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
